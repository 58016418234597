import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Services from './components/Services';
import AboutUs from './components/AboutUs';
import Treasury from './components/Treasury';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/treasury" element={<Treasury />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about-exa" element={<AboutUs />} />
      </Routes>
    </Router>
  );
}

export default App;
