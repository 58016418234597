import React, { useState, useEffect } from 'react';
import { Menu, X, ChevronRight, Hexagon, FileText, Users, Linkedin, Twitter } from 'lucide-react';
import { Link } from 'react-router-dom';

const NavItem = ({ item, scrolled }) => {
    const href = item === 'Home' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`;
    return (
        <Link to={href} className={`transition-all duration-300 font-medium ${scrolled ? 'text-white hover:text-gray-200' : 'text-white hover:text-gray-200'}`} onClick={() => window.scrollTo(0, 0)}>
            {item}
        </Link>
    );
};

const ServiceCard = ({ icon: Icon, title, description }) => {
    return (
        <div className="bg-white p-6 rounded-2xl shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-1 flex flex-col items-center">
            <Icon className="w-12 h-12 text-black mb-4" />
            <h3 className="text-xl font-bold mb-3 text-center">{title}</h3>
            <p className="text-gray-800 text-sm text-center">{description}</p>
        </div>
    );
};

const Services = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [landingPadding, setLandingPadding] = useState('pt-40 pb-32 md:pt-56 md:pb-40');

    const navItems = ['Services', 'Research', 'About Exa', 'Treasury'];

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrolled(scrollPosition > 50);

            if (scrollPosition > 500) {
                setLandingPadding('pt-24 pb-20 md:pt-32 md:pb-24');
            } else if (scrollPosition > 200) {
                setLandingPadding('pt-32 pb-24 md:pt-40 md:pb-32');
            } else {
                setLandingPadding('pt-40 pb-32 md:pt-56 md:pb-40');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="font-sans bg-white text-black">
            <header className={`fixed w-full top-0 z-50 transition-all duration-300 ${scrolled ? 'bg-black shadow-lg py-3' : 'bg-transparent py-4'}`}>
                <div className="container mx-auto px-6">
                    <div className="flex justify-between items-center">
                        <Link to="/" className={`text-2xl md:text-3xl font-bold transition-all duration-300 ${scrolled ? 'text-white' : 'text-white'}`} onClick={() => window.scrollTo(0, 0)}>ExaGroup</Link>
                        <nav className="hidden md:block">
                            <ul className="flex space-x-8">
                                {navItems.map((item) => (
                                    <li key={item}><NavItem item={item} scrolled={scrolled} /></li>
                                ))}
                            </ul>
                        </nav>
                        <a href="https://calendly.com/exaresearch" target="_blank" rel="noopener noreferrer" className={`hidden md:block px-8 py-3 rounded-full text-base font-bold transition-all duration-300 ${scrolled ? 'bg-white text-black hover:bg-gray-200' : 'bg-white text-black hover:bg-gray-200'}`}>
                            BOOK CALL
                        </a>
                        <button
                            className="md:hidden"
                            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                            aria-label={mobileMenuOpen ? "Close menu" : "Open menu"}
                        >
                            {mobileMenuOpen ? <X size={24} className={scrolled ? 'text-white' : 'text-white'} /> : <Menu size={24} className={scrolled ? 'text-white' : 'text-white'} />}
                        </button>
                    </div>
                </div>
            </header>

            <div
                className={`fixed inset-0 bg-black bg-opacity-75 z-40 transition-opacity duration-300 ${mobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                onClick={() => setMobileMenuOpen(false)}
            >
                <div
                    className={`fixed right-0 top-0 bottom-0 w-3/4 bg-black shadow-2xl transform transition-transform duration-300 ease-in-out ${mobileMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <nav className="p-6 pt-20">
                        <ul className="space-y-6">
                            {navItems.map((item) => (
                                <li key={item}><NavItem item={item} scrolled={true} /></li>
                            ))}
                            <li>
                                <a href="https://calendly.com/exaresearch" target="_blank" rel="noopener noreferrer" className="w-full bg-white text-black px-8 py-4 rounded-full text-lg font-bold hover:bg-gray-200 transition mt-8 block text-center">BOOK CALL</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <section className={`relative bg-black text-white ${landingPadding} rounded-b-[10rem] shadow-2xl transition-all duration-500`}>
                <div className="container mx-auto px-6">
                    <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-center mb-6">
                        Crafting Tomorrow's Token Ecosystems
                    </h1>
                    <p className="text-center text-base md:text-lg lg:text-xl text-gray-300 mb-12 max-w-3xl mx-auto">
                        Our services include token system and economy design, token modeling, market entry strategies,
                        community & governance frameworks, and partner introductions.
                    </p>
                </div>
            </section>

            <section className="py-24 bg-white">
                <div className="container mx-auto px-6">
                    <div className="flex flex-col md:flex-row items-start">
                        <div className="md:w-1/2 mb-8 md:mb-0 md:sticky md:top-24">
                            <div className="aspect-w-4 aspect-h-3 mx-auto max-w-md">
                                <img
                                    src="/Worked for Logos copy/logo enhanced.png"
                                    alt="ExaGroup Logo"
                                    className="w-full h-full object-cover rounded-2xl shadow-xl"
                                />
                            </div>
                        </div>
                        <div className="md:w-1/2 md:pl-8">
                            <h2 className="text-2xl md:text-3xl font-bold mb-4">
                                We Build Sustainable Tokenized Ventures with a Data-Driven Methodology
                            </h2>
                            <p className="text-base md:text-lg text-gray-800 mb-8">
                                At ExaGroup, our tokenomics consulting service offers expert analysis and bespoke
                                solutions to help you build and launch sustainable tokenized ventures. We utilize
                                cutting-edge innovations, industry insights, and proven strategies to validate and
                                stress-test your token economy.
                            </p>

                            <h2 className="text-2xl md:text-3xl font-bold mb-4">
                                A Strategic Partner for Blockchain-based Projects to go from Zero to Hero
                            </h2>
                            <p className="text-base md:text-lg text-gray-800 mb-4">
                                ExaGroup combines strong experience in both Web3 and Web2, making us the ideal
                                partner to create strategies that put users at the heart of product development while
                                using the latest in blockchain technology.
                            </p>
                            <p className="text-base md:text-lg text-gray-800 mb-12">
                                We offer services ranging from designing tokenomics to creating practical Go-To-Market
                                plans and providing customized treasury management and governance strategies to help
                                build a solid foundation for your venture. With our expertise in forming partnerships with
                                top exchanges, market makers, and marketing partners, we help expand your network
                                and support your growth.
                            </p>

                            <div className="grid grid-cols-1 gap-8">
                                <ServiceCard
                                    icon={Hexagon}
                                    title="Token Economy, Modeling, Audits & Market Entry Strategies"
                                    description="Our tokenomics analysis and market entry strategies help you navigate the complex world of blockchain and cryptocurrencies, ensuring a successful launch and sustainable growth."
                                />
                                <ServiceCard
                                    icon={FileText}
                                    title="Investment & Fundraise Documentation"
                                    description="Our team will assist you in preparing for investment by crafting or reviewing your whitepaper and pitch deck, ensuring they are investment-ready."
                                />
                                <ServiceCard
                                    icon={Users}
                                    title="Core Web3 & Community Programs"
                                    description="With a vast product background, we meticulously prepare and execute the launch of essential Web3 community programs, from grants and ambassador programs to establishing governance frameworks."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="bg-black text-white py-20">
                <div className="container mx-auto px-6">
                    <div className="flex flex-col items-center mb-16">
                        <Link to="/" className="text-3xl font-bold mb-8" onClick={() => window.scrollTo(0, 0)}>ExaGroup</Link>
                        <nav>
                            <ul className="flex flex-wrap justify-center space-x-4 md:space-x-10">
                                <li className="w-full sm:w-auto text-center mb-4"><a href="mailto:research@exagroup.xyz" className="text-lg hover:text-gray-200 transition flex items-center justify-center"><FileText className="mr-3" size={24} />Contact</a></li>
                                <li className="w-full sm:w-auto text-center mb-4"><a href="https://www.linkedin.com/company/exagroupxyz/about/" target="_blank" rel="noopener noreferrer" className="text-lg hover:text-gray-200 transition flex items-center justify-center"><Linkedin className="mr-3" size={24} />LinkedIn</a></li>
                                <li className="w-full sm:w-auto text-center mb-4"><a href="https://x.com/exagroupxyz" target="_blank" rel="noopener noreferrer" className="text-lg hover:text-gray-200 transition flex items-center justify-center"><Twitter className="mr-3" size={24} />Twitter</a></li>
                                <li className="w-full sm:w-auto text-center mb-4"><a href="https://blog.exagroup.xyz/" target="_blank" rel="noopener noreferrer" className="text-lg hover:text-gray-200 transition flex items-center justify-center"><FileText className="mr-3" size={24} />Research</a></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="text-center">
                        <p>43 Portsea Hall, Portsea Place, W2 2BW, London</p>
                    </div>
                    <hr className="border-gray-700 my-10" />
                    <p className="text-center text-gray-400 text-sm">&copy; 2024 EXA RESEARCH LIMITED. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default Services;