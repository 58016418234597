import React, { useState, useEffect } from 'react';
import { Menu, X, ChevronRight, FileText, Linkedin, Twitter } from 'lucide-react';
import { Link } from 'react-router-dom';

const NavItem = ({ item, scrolled }) => {
    const href = item === 'Home' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`;
    return (
        <Link to={href} className={`transition-all duration-300 font-medium ${scrolled ? 'text-white hover:text-gray-200' : 'text-white hover:text-gray-200'}`} onClick={() => window.scrollTo(0, 0)}>
            {item}
        </Link>
    );
};

const AboutUs = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [landingPadding, setLandingPadding] = useState('pt-40 pb-32 md:pt-56 md:pb-40');

    const navItems = ['Services', 'Research', 'About Exa', 'Treasury'];

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrolled(scrollPosition > 50);

            if (scrollPosition > 500) {
                setLandingPadding('pt-24 pb-20 md:pt-32 md:pb-24');
            } else if (scrollPosition > 200) {
                setLandingPadding('pt-32 pb-24 md:pt-40 md:pb-32');
            } else {
                setLandingPadding('pt-40 pb-32 md:pt-56 md:pb-40');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const coreContributors = [
        {
            name: 'Gary',
            role: 'Tokenomics and GTM',
            description: 'Web3 Generalist and builder, passionate about open data, privacy, and decentralized technologies.',
            image: '/Worked for Logos copy/Profile Pictures/gary.png',
            linkedin: 'https://www.linkedin.com/in/lattagher/'
        },
        {
            name: 'Andrea',
            role: 'Tokenomics and Product Strategy',
            description: 'Passionate about OSS, Decentralized AI and DeFi with strong competencies in Token Design, Financial Markets, Social Economics and Product Management',
            image: '/Worked for Logos copy/Profile Pictures/andrea.jpg',
            linkedin: 'https://www.linkedin.com/in/andreaarmanni/'
        },
        {
            name: 'Noelle',
            role: 'Operations',
            description: "A versatile DLT ambassador and advisor focused on onboarding companies, data scientists, tech hippies and builders onto the decentralized data economy, AI and IoT bandwagon.",
            image: '/Worked for Logos copy/Profile Pictures/noelle.png',
            linkedin: 'https://www.linkedin.com/in/noellesiri/'
        }
    ];

    const trustedBy = [
        '/Worked for Logos copy/enso.finance.jpg',
        '/Worked for Logos copy/forefront.market.jpeg',
        '/Worked for Logos copy/fractal.id.jpeg',
        '/Worked for Logos copy/near.org.png',
        '/Worked for Logos copy/oceanprotocol.com.jpg',
        '/Worked for Logos copy/roko.network.webp',
        '/Worked for Logos copy/t2.world.png',
        '/Worked for Logos copy/uco.network.jpeg'
    ];

    return (
        <div className="font-sans bg-white text-black">
            <header className={`fixed w-full top-0 z-50 transition-all duration-300 ${scrolled ? 'bg-black shadow-lg py-3' : 'bg-transparent py-4'}`}>
                <div className="container mx-auto px-6">
                    <div className="flex justify-between items-center">
                        <Link to="/" className={`text-2xl md:text-3xl font-bold transition-all duration-300 ${scrolled ? 'text-white' : 'text-white'}`} onClick={() => window.scrollTo(0, 0)}>ExaGroup</Link>
                        <nav className="hidden md:block">
                            <ul className="flex space-x-8">
                                {navItems.map((item) => (
                                    <li key={item}><NavItem item={item} scrolled={scrolled} /></li>
                                ))}
                            </ul>
                        </nav>
                        <a href="https://calendly.com/exaresearch" target="_blank" rel="noopener noreferrer" className={`hidden md:block px-8 py-3 rounded-full text-base font-bold transition-all duration-300 ${scrolled ? 'bg-white text-black hover:bg-gray-200' : 'bg-white text-black hover:bg-gray-200'}`}>
                            BOOK CALL
                        </a>
                        <button
                            className="md:hidden"
                            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                            aria-label={mobileMenuOpen ? "Close menu" : "Open menu"}
                        >
                            {mobileMenuOpen ? <X size={24} className={scrolled ? 'text-white' : 'text-white'} /> : <Menu size={24} className={scrolled ? 'text-white' : 'text-white'} />}
                        </button>
                    </div>
                </div>
            </header>

            <div
                className={`fixed inset-0 bg-black bg-opacity-75 z-40 transition-opacity duration-300 ${mobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                onClick={() => setMobileMenuOpen(false)}
            >
                <div
                    className={`fixed right-0 top-0 bottom-0 w-3/4 bg-black shadow-2xl transform transition-transform duration-300 ease-in-out ${mobileMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <nav className="p-6 pt-20">
                        <ul className="space-y-6">
                            {navItems.map((item) => (
                                <li key={item}><NavItem item={item} scrolled={true} /></li>
                            ))}
                            <li>
                                <a href="https://calendly.com/exaresearch" target="_blank" rel="noopener noreferrer" className="w-full bg-white text-black px-8 py-4 rounded-full text-lg font-bold hover:bg-gray-200 transition mt-8 block text-center">BOOK CALL</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <section className={`relative bg-black text-white ${landingPadding} rounded-b-[10rem] shadow-2xl transition-all duration-500`}>
                <div className="container mx-auto px-6">
                    <div className="mb-12 text-center">
                        <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6">Building the Future</h2>
                        <p className="text-lg md:text-xl lg:text-2xl text-gray-300 max-w-3xl mx-auto">
                            A team of Web3 contributors committed to democratizing access to
                            public capital markets through the development of FOSS for
                            sustainable token economies.
                        </p>
                    </div>
                </div>
            </section>

            <section className="py-24 bg-white">
                <div className="container mx-auto px-6">
                    <div className="mb-20">
                        <h3 className="text-3xl md:text-4xl font-bold mb-8 text-center">Core Contributors</h3>
                        <p className="text-lg md:text-xl text-gray-800 mb-12 text-center">
                            The Web3 expertise within our team amounts to a collective 20+ years of experience
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                            {coreContributors.map((contributor, index) => (
                                <div key={index} className="text-center">
                                    <div className="w-36 h-36 md:w-40 md:h-40 rounded-full mx-auto mb-4 overflow-hidden bg-gray-200 shadow-xl">
                                        <img
                                            src={contributor.image}
                                            alt={contributor.name}
                                            className="w-full h-full object-cover"
                                            onError={(e) => {
                                                console.error(`Failed to load image: ${e.target.src}`);
                                                e.target.onerror = null;
                                                e.target.src = '/placeholder-profile.jpg';
                                            }}
                                        />
                                    </div>
                                    <h4 className="text-xl md:text-2xl font-bold mb-2">{contributor.name}</h4>
                                    <p className="text-lg text-gray-800 mb-4">{contributor.role}</p>
                                    <p className="text-sm md:text-base text-gray-600 mb-4">{contributor.description}</p>
                                    <a href={contributor.linkedin} target="_blank" rel="noopener noreferrer" className="inline-block">
                                        <Linkedin className="w-6 h-6 text-gray-500 hover:text-gray-700 transition" />
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="mb-20">
                        <h3 className="text-3xl md:text-4xl font-bold mb-12 text-center">Worked For Industry Leaders</h3>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                            {trustedBy.map((logo, index) => (
                                <div key={index} className="bg-white shadow-lg rounded-2xl overflow-hidden flex items-center justify-center p-4">
                                    <img
                                        src={logo}
                                        alt={`Trusted company ${index + 1}`}
                                        className="max-w-full max-h-16 object-contain"
                                        onError={(e) => {
                                            console.error(`Failed to load image: ${e.target.src}`);
                                            e.target.onerror = null;
                                            e.target.src = '/placeholder-logo.jpg';
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="text-center">
                    </div>
                </div>
            </section>

            <footer className="bg-black text-white py-20">
                <div className="container mx-auto px-6">
                    <div className="flex flex-col items-center mb-16">
                        <Link to="/" className="text-3xl font-bold mb-8" onClick={() => window.scrollTo(0, 0)}>ExaGroup</Link>
                        <nav>
                            <ul className="flex flex-wrap justify-center space-x-4 md:space-x-10">
                                <li className="w-full sm:w-auto text-center mb-4"><a href="mailto:research@exagroup.xyz" className="text-lg hover:text-gray-200 transition flex items-center justify-center"><FileText className="mr-3" size={24} />Contact</a></li>
                                <li className="w-full sm:w-auto text-center mb-4"><a href="https://www.linkedin.com/company/exagroupxyz/about/" target="_blank" rel="noopener noreferrer" className="text-lg hover:text-gray-200 transition flex items-center justify-center"><Linkedin className="mr-3" size={24} />LinkedIn</a></li>
                                <li className="w-full sm:w-auto text-center mb-4"><a href="https://x.com/exagroupxyz" target="_blank" rel="noopener noreferrer" className="text-lg hover:text-gray-200 transition flex items-center justify-center"><Twitter className="mr-3" size={24} />Twitter</a></li>
                                <li className="w-full sm:w-auto text-center mb-4"><a href="https://blog.exagroup.xyz/" target="_blank" rel="noopener noreferrer" className="text-lg hover:text-gray-200 transition flex items-center justify-center"><FileText className="mr-3" size={24} />Research</a></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="text-center">
                        <p>43 Portsea Hall, Portsea Place, W2 2BW, London</p>
                    </div>
                    <hr className="border-gray-700 my-10" />
                    <p className="text-center text-gray-400 text-sm">&copy; 2024 EXA RESEARCH LIMITED. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default AboutUs;