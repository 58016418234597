import React, { useState, useEffect } from 'react';
import { Menu, X, ChevronRight, Building2, Network, Coins, FileText, Linkedin, Twitter, Shield, BarChart, Cog, Lock, LineChart, Settings, Target } from 'lucide-react';
import { Link } from 'react-router-dom';

const NavItem = ({ item, scrolled }) => {
    const href = item === 'Home' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`;
    return (
        <Link to={href} className={`transition-all duration-300 font-medium ${scrolled ? 'text-white hover:text-gray-200' : 'text-white hover:text-gray-200'}`} onClick={() => window.scrollTo(0, 0)}>
            {item}
        </Link>
    );
};

const ExpertiseCard = ({ icon: Icon, title, description }) => {
    return (
        <div className="bg-white p-6 rounded-2xl shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-1 flex flex-col items-center">
            <Icon className="w-12 h-12 text-black mb-4" />
            <h3 className="text-xl font-bold mb-3 text-center">{title}</h3>
            <p className="text-gray-800 text-sm text-center">{description}</p>
        </div>
    );
};

const StrategyCard = ({ icon: Icon, title, points }) => {
    return (
        <div className="bg-gray-50 p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300">
            <div className="flex items-center mb-6">
                <Icon className="w-8 h-8 text-black mr-4" />
                <h3 className="text-xl font-bold">{title}</h3>
            </div>
            <ul className="space-y-3">
                {points.map((point, index) => (
                    <li key={index} className="flex items-start">
                        <ChevronRight className="w-5 h-5 text-gray-600 mt-1 flex-shrink-0" />
                        <span className="text-gray-800 ml-2">{point}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const DetailSection = ({ icon: Icon, title, description }) => {
    return (
        <div className="bg-white p-8 rounded-2xl shadow-xl transition-all duration-300 hover:shadow-2xl">
            <div className="flex items-center mb-4">
                <Icon className="w-6 h-6 text-black mr-3" />
                <h3 className="text-xl font-bold">{title}</h3>
            </div>
            <p className="text-gray-700">{description}</p>
        </div>
    );
};

const Treasury = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [landingPadding, setLandingPadding] = useState('pt-40 pb-32 md:pt-56 md:pb-40');

    const navItems = ['Services', 'Research', 'About Exa', 'Treasury'];

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrolled(scrollPosition > 50);

            if (scrollPosition > 500) {
                setLandingPadding('pt-24 pb-20 md:pt-32 md:pb-24');
            } else if (scrollPosition > 200) {
                setLandingPadding('pt-32 pb-24 md:pt-40 md:pb-32');
            } else {
                setLandingPadding('pt-40 pb-32 md:pt-56 md:pb-40');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const strategies = [
        {
            icon: Shield,
            title: "Treasury Protection & Growth Strategy",
            points: [
                "Core focus on capital preservation through battle-tested yield strategies",
                "Strategic deployment across multiple DeFi ecosystems for optimal diversification",
                "Deep relationships with leading DeFi protocols enabling access to exclusive opportunities",
                "Access to exclusive on-chain private deals for enhanced yields",
                "Implementation of sophisticated hedging strategies",
                "Regular portfolio rebalancing to maintain optimal risk-adjusted returns",
                "Continuous monitoring of protocol health and market conditions"
            ]
        },
        {
            icon: BarChart,
            title: "Return Projections & Flexibility",
            points: [
                "Detailed scenarios reflecting current market conditions, net of all fees",
                "Conservative baseline projections with upside potential",
                "Customizable risk tolerance and investment objectives",
                "Dynamic portfolio adjustment based on market conditions",
                "Regular performance reviews and strategy optimization",
                "Market dynamics impact analysis and adaptation",
                "Transparent reporting on all activities and results"
            ]
        },
        {
            icon: Cog,
            title: "Operational Framework",
            points: [
                "Exclusive focus on on-chain treasury management and yield generation",
                "Seamless integration with existing market makers for OTC conversions",
                "Clear division of responsibilities ensuring operational efficiency",
                "Foundation setup and ongoing legal management support",
                "Transparent reporting and communication channels",
                "Dedicated support team for immediate response",
                "Regular stakeholder updates and governance participation"
            ]
        },
        {
            icon: Lock,
            title: "Technical Infrastructure",
            points: [
                "Proprietary tech stack with automated guardians and real-time monitoring",
                "Multi-layered risk management system with instant response capabilities",
                "Non-custodial setup with granular permissions through Safe smart contracts",
                "Advanced security protocols and regular audits",
                "Automated monitoring and alert systems",
                "Redundant backup systems and disaster recovery protocols",
                "Regular security assessments and penetration testing"
            ]
        }
    ];

    const additionalDetails = [
        {
            icon: LineChart,
            title: "Performance Tracking",
            description: "Comprehensive performance monitoring with detailed analytics and reporting on all treasury activities, including yield generation, risk metrics, and market exposure."
        },
        {
            icon: Settings,
            title: "Protocol Integration",
            description: "Seamless integration with major DeFi protocols and platforms, enabling efficient capital deployment and risk management across multiple ecosystems."
        },
        {
            icon: Target,
            title: "Risk Management",
            description: "Multi-layered risk management approach combining automated systems, human oversight, and strategic diversification to protect treasury assets."
        }
    ];

    return (
        <div className="font-sans bg-white text-black">
            <header className={`fixed w-full top-0 z-50 transition-all duration-300 ${scrolled ? 'bg-black shadow-lg py-3' : 'bg-transparent py-4'}`}>
                <div className="container mx-auto px-6">
                    <div className="flex justify-between items-center">
                        <Link to="/" className={`text-2xl md:text-3xl font-bold transition-all duration-300 ${scrolled ? 'text-white' : 'text-white'}`} onClick={() => window.scrollTo(0, 0)}>ExaGroup</Link>
                        <nav className="hidden md:block">
                            <ul className="flex space-x-8">
                                {navItems.map((item) => (
                                    <li key={item}><NavItem item={item} scrolled={scrolled} /></li>
                                ))}
                            </ul>
                        </nav>
                        <a href="https://calendly.com/exaresearch" target="_blank" rel="noopener noreferrer" className={`hidden md:block px-8 py-3 rounded-full text-base font-bold transition-all duration-300 ${scrolled ? 'bg-white text-black hover:bg-gray-200' : 'bg-white text-black hover:bg-gray-200'}`}>
                            BOOK CALL
                        </a>
                        <button
                            className="md:hidden"
                            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                            aria-label={mobileMenuOpen ? "Close menu" : "Open menu"}
                        >
                            {mobileMenuOpen ? <X size={24} className={scrolled ? 'text-white' : 'text-white'} /> : <Menu size={24} className={scrolled ? 'text-white' : 'text-white'} />}
                        </button>
                    </div>
                </div>
            </header>

            <div
                className={`fixed inset-0 bg-black bg-opacity-75 z-40 transition-opacity duration-300 ${mobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                onClick={() => setMobileMenuOpen(false)}
            >
                <div
                    className={`fixed right-0 top-0 bottom-0 w-3/4 bg-black shadow-2xl transform transition-transform duration-300 ease-in-out ${mobileMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <nav className="p-6 pt-20">
                        <ul className="space-y-6">
                            {navItems.map((item) => (
                                <li key={item}><NavItem item={item} scrolled={true} /></li>
                            ))}
                            <li>
                                <a href="https://calendly.com/exaresearch" target="_blank" rel="noopener noreferrer" className="w-full bg-white text-black px-8 py-4 rounded-full text-lg font-bold hover:bg-gray-200 transition mt-8 block text-center">BOOK CALL</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <section className={`relative bg-black text-white ${landingPadding} rounded-b-[10rem] shadow-2xl transition-all duration-500`}>
                <div className="container mx-auto px-6">
                    <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-center mb-6">
                        Open and Transparent Finance for DAOs
                    </h1>
                    <p className="text-center text-base md:text-lg lg:text-xl text-gray-300 mb-12 max-w-3xl mx-auto">
                       Finance should be open, transparent, and accessible to everyone.
                    </p>
                </div>
            </section>

            <section className="py-24 bg-white">
                <div className="container mx-auto px-6">
                    <div className="mb-16 text-center">
                        <p className="text-lg md:text-xl text-gray-800 max-w-4xl mx-auto">
                            We are passionate about democratizing capital markets and enhancing capital efficiency to empower DAOs and their communities.
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
                        <ExpertiseCard
                            icon={Building2}
                            title="Unmatched Expertise in Decentralized Finance"
                            description="With over four years of hands-on experience in DeFi, we’ve extensively researched, interacted with, and deployed capital across various protocols. This practical knowledge allows us to design and implement effective, on-chain financial systems tailored to the unique challenges of decentralized organizations."
                        />
                        <ExpertiseCard
                            icon={Network}
                            title="Unparalleled Network"
                            description="Carefully cultivated through professionalism, reactivity and strong collaborating with market leaders."
                        />
                        <ExpertiseCard
                            icon={Coins}
                            title="Specialists in Stablecoin & ETH Strategy"
                            description="Stablecoins and DeFi lie at the heart of our expertise. By integrating off-chain risk assessments with fully on-chain execution, we provide seamless solutions in collaboration with trusted partners like Safe and Gauntlet. Our focus is always on advocating for our clients’ interests and ensuring their long-term success in an ever-evolving financial landscape."
                        />
                    </div>
                </div>
            </section>

            <section className="py-24 bg-gray-100">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
                        Comprehensive Treasury Management Framework
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {strategies.map((strategy, index) => (
                            <StrategyCard
                                key={index}
                                icon={strategy.icon}
                                title={strategy.title}
                                points={strategy.points}
                            />
                        ))}
                    </div>
                </div>
            </section>

            <section className="py-24 bg-white">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center">
                        Advanced Treasury Solutions
                    </h2>
                    <p className="text-lg text-gray-800 max-w-4xl mx-auto text-center mb-16">
                        Our approach combines deep DeFi expertise with institutional-grade risk management to protect and grow your treasury assets while maintaining full transparency and control.
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {additionalDetails.map((detail, index) => (
                            <DetailSection
                                key={index}
                                icon={detail.icon}
                                title={detail.title}
                                description={detail.description}
                            />
                        ))}
                    </div>
                </div>
            </section>

            <footer className="bg-black text-white py-20">
                <div className="container mx-auto px-6">
                    <div className="flex flex-col items-center mb-16">
                        <Link to="/" className="text-3xl font-bold mb-8" onClick={() => window.scrollTo(0, 0)}>ExaGroup</Link>
                        <nav>
                            <ul className="flex flex-wrap justify-center space-x-4 md:space-x-10">
                                <li className="w-full sm:w-auto text-center mb-4"><a href="mailto:research@exagroup.xyz" className="text-lg hover:text-gray-200 transition flex items-center justify-center"><FileText className="mr-3" size={24} />Contact</a></li>
                                <li className="w-full sm:w-auto text-center mb-4"><a href="https://www.linkedin.com/company/exagroupxyz/about/" target="_blank" rel="noopener noreferrer" className="text-lg hover:text-gray-200 transition flex items-center justify-center"><Linkedin className="mr-3" size={24} />LinkedIn</a></li>
                                <li className="w-full sm:w-auto text-center mb-4"><a href="https://x.com/exagroupxyz" target="_blank" rel="noopener noreferrer" className="text-lg hover:text-gray-200 transition flex items-center justify-center"><Twitter className="mr-3" size={24} />Twitter</a></li>
                                <li className="w-full sm:w-auto text-center mb-4"><a href="https://blog.exagroup.xyz/" target="_blank" rel="noopener noreferrer" className="text-lg hover:text-gray-200 transition flex items-center justify-center"><FileText className="mr-3" size={24} />Research</a></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="text-center">
                        <p>43 Portsea Hall, Portsea Place, W2 2BW, London</p>
                    </div>
                    <hr className="border-gray-700 my-10" />
                    <p className="text-center text-gray-400 text-sm">&copy; 2024 EXA RESEARCH LIMITED. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default Treasury;
